<template>
  <v-container fluid>
    <v-snackbar top :timeout="3000" v-model="snackbar">
      {{ snackbarText }}
    </v-snackbar>

    <v-row class="pa-0 ma-0 ml-5 mt-5">
      <h1>
        Webhook de
        <span class="font-weight-light">{{
          selectedCorporative.shortName
        }}</span>
      </h1>
    </v-row>
    <v-row class="mt-3 ml-5">
      <v-col cols="12" sm="3" md="3">
        <v-select
          rounded
          disabled
          filled
          label="Método"
          placeholder="Método"
          :items="methods"
          v-model="method"
        />
      </v-col>
      <v-col cols="12" sm="7" md="7">
        <v-text-field
          label="URL"
          rounded
          filled
          placeholder="Url"
          v-model="url"
        />
      </v-col>
    </v-row>

    <v-row class="ml-5">
      <v-col cols="12" sm="3" md="3">
        <v-select
          rounded
          filled
          label="Tipo de contenido"
          :items="types"
          v-model="selectedType"
        />
      </v-col>

      <v-col cols="12" sm="4" md="4">
        <v-text-field
          append-icon="fas fa-sync-alt"
          rounded
          filled
          readonly
          label="Llave secreta"
          v-model="secret"
          @click:append="generateKey"
        />
      </v-col>

      <v-col cols="12" sm="3" md="3">
        <!-- <v-text-field rounded filled label="Llave secreta" v-model="secret" /> -->
        <v-btn height="45" class="mt-1" block color="primary">Actualizar</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>
  
  <script>
import { mapState } from "vuex";
import { customAlphabet } from "nanoid";

export default {
  name: "corporative-webhook",

  data() {
    return {
      snackbar: false,
      snackbarText: "",
      loading: true,
      url: "https://",
      method: "post",
      secret: "",
      methods: [
        { text: "POST", value: "post" },
        { text: "GET", value: "get" },
        { text: "DELETE", value: "delete" },
      ],
      selectedType: "APPLICATION/JSON",
      types: [
        { text: "APPLICATION/JSON", value: "APPLICATION/JSON" },
        {
          text: "APPLICATION/X-WWW-FORM-URLENCODED",
          value: "APPLICATION/X-WWW-FORM-URLENCODED",
        },
        { text: "XML-HTTP-REQUEST", value: "XMLHTTPREQUEST" },
      ],
    };
  },
  watch: {
    selectedCorporative() {
      //   this.keys = [];
      this.getBusinessData();
    },
  },
  computed: {
    ...mapState(["selectedCorporative", "search", "user"]),
  },
  methods: {
    copyToClipboard(text) {
      navigator.clipboard.writeText(text).then(
        () => {
          this.snackbar = true;
          this.snackbarText = "Copiado al portapapeles";
        },
        (err) => {
          console.error("Async: Could not copy text: ", err);
        }
      );
    },

    generateKey() {
      let nanoid = customAlphabet(
        "123456789abcdefghijklmnpqrstuvwxyzABCDEFGHIJKLMNPQRSTUVWXYZ",
        10
      );

      this.secret = nanoid(18);
    }
  },

  mounted() {
    this.$store.state.visibleSearch = false;
    this.loading = false;
  },
};
</script>
  